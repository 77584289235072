var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Volumetric pipets & flasks, burets, and graduated cylinders all have markings (lines) etched into the glassware denoting volume levels. Correctly recording the volumes from these pieces of glassware incorporates the following four concepts: ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Meniscus: The meniscus is the "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px","max-height":"20px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}}),_vm._v(" of the liquid surface in the glassware. If the liquid contained in the glassware is polar, adhesion is "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}}),_vm._v(" cohesion, leading to a "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"c"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_vm._v(" meniscus. If the liquid is nonpolar, adhesion is "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"d"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}}),_vm._v(" cohesion, leading to a "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"e"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}}),_vm._v(" meniscus. ")],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Parallax Error: To avoid parallax error, you must have your eyes level to the "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"f"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Significant Figures: The number of digits used when recording the volume from each piece of glassware is determined by the "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items5,"item-text":"text","item-value":"value","label":"g"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}}),_vm._v(" for burets and graduated cylinders and by "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items5,"item-text":"text","item-value":"value","label":"h"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input8),callback:function ($$v) {_vm.$set(_vm.inputs, "input8", $$v)},expression:"inputs.input8"}}),_vm._v(" for volumetric pipets & flasks. ")],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Temperature: The temperature of the liquid held by glassware "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items6,"item-text":"text","item-value":"value","label":"i"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input9),callback:function ($$v) {_vm.$set(_vm.inputs, "input9", $$v)},expression:"inputs.input9"}}),_vm._v(" affect volume measurements, therefore, all liquids should be at "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items7,"item-text":"text","item-value":"value","label":"j"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input10),callback:function ($$v) {_vm.$set(_vm.inputs, "input10", $$v)},expression:"inputs.input10"}}),_vm._v(" . ")],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }