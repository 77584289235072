<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Volumetric pipets & flasks, burets, and graduated cylinders all have markings (lines) etched
        into the glassware denoting volume levels. Correctly recording the volumes from these pieces
        of glassware incorporates the following four concepts:
      </p>

      <p class="mb-2">
        Meniscus: The meniscus is the
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; max-width: 150px; max-height: 20px"
          :items="items1"
          item-text="text"
          item-value="value"
          label="a"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        of the liquid surface in the glassware. If the liquid contained in the glassware is polar,
        adhesion is
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; max-width: 150px"
          :items="items2"
          item-text="text"
          item-value="value"
          label="b"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        cohesion, leading to a
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; max-width: 150px"
          :items="items3"
          item-text="text"
          item-value="value"
          label="c"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        meniscus. If the liquid is nonpolar, adhesion is
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; max-width: 150px"
          :items="items2"
          item-text="text"
          item-value="value"
          label="d"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        cohesion, leading to a
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; max-width: 150px"
          :items="items3"
          item-text="text"
          item-value="value"
          label="e"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        meniscus.
      </p>

      <p class="mb-2">
        Parallax Error: To avoid parallax error, you must have your eyes level to the
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; max-width: 150px"
          :items="items4"
          item-text="text"
          item-value="value"
          label="f"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        Significant Figures: The number of digits used when recording the volume from each piece of
        glassware is determined by the
        <v-select
          v-model="inputs.input7"
          style="display: inline-block; max-width: 150px"
          :items="items5"
          item-text="text"
          item-value="value"
          label="g"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        for burets and graduated cylinders and by
        <v-select
          v-model="inputs.input8"
          style="display: inline-block; max-width: 150px"
          :items="items5"
          item-text="text"
          item-value="value"
          label="h"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        for volumetric pipets & flasks.
      </p>

      <p class="mb-2">
        Temperature: The temperature of the liquid held by glassware
        <v-select
          v-model="inputs.input9"
          style="display: inline-block; max-width: 150px"
          :items="items6"
          item-text="text"
          item-value="value"
          label="i"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        affect volume measurements, therefore, all liquids should be at
        <v-select
          v-model="inputs.input10"
          style="display: inline-block; max-width: 150px"
          :items="items7"
          item-text="text"
          item-value="value"
          label="j"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        .
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S2Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
        input10: null,
      },
      items1: [
        {text: 'Curvature', value: 'curvature'},
        {text: 'Level', value: 'level'},
        {text: 'Polarity', value: 'polarity'},
        {text: 'Tension', value: 'tension'},
      ],
      items2: [
        {text: 'Greater than', value: 'greaterThan'},
        {text: 'Less than', value: 'lessThan'},
        {text: 'Equal to', value: 'equalTo'},
      ],
      items3: [
        {text: 'Concave', value: 'concave'},
        {text: 'Convex', value: 'convex'},
      ],
      items4: [
        {text: 'Top or bottom of the meniscus', value: 'meniscus'},
        {text: 'Middle of the glassware', value: 'middle'},
        {text: 'Center of your goggles', value: 'center'},
        {text: 'Benchtop', value: 'benchtop'},
      ],
      items5: [
        {
          text: 'Numbers from markings + a last number estimated between markings',
          value: 'markings',
        },
        {text: "Glassware's total volume", value: 'volume'},
        {text: 'Etched value on glassware (+/- 0.05 mL)', value: 'etched'},
        {text: 'Information provided in the course material', value: 'info'},
      ],
      items6: [
        {text: 'Does', value: 'does'},
        {text: 'Does not', value: 'doesNot'},
      ],
      items7: [
        {text: 'Room temperature', value: 'roomTemp'},
        {text: '$0\\,\\text{Celsius}$', value: 'zero'},
        {text: '$273\\,\\text{K}$', value: '273'},
        {text: "The liquid's boiling point", value: 'boiling'},
      ],
    };
  },
};
</script>
<style scoped></style>
